import React, { useState } from "react";
import ManageProductsListings from "./ManageProductListings";
function ManageProducts({ products, setProducts, categories, token,stores}) {
  const [newProduct, setNewProduct] = useState({
    name: "",
    price: "",
    description: "",
    category: "",
    stock: "",
    type: "simple",
    variations: [],
    store:""
  });
  const [image, setImage] = useState(null);
  //const [stores, setStores] = useState([]); // To hold the list of stores

  const [editProductId, setEditProductId] = useState(null);
  
  

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prev) => ({ ...prev, [name]: value }));
  };
  /*const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log("File selected:", file); // Debug log
    if (file) {
      setNewProduct((prev) => ({
        ...prev,
        image: file,
      }));
    }
  };
  **/
 /*const handleImageChange = (e) => {
    setImage(e.target.files[0]);

  };**/
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
  
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      image: selectedFile, // Add image file to the state
    }));
  };

  const handleAddVariation = () => {
    setNewProduct((prev) => ({
      ...prev,
      variations: [...prev.variations, { size: "", price: "" }],
    }));
  };

  const handleVariationChange = (index, field, value) => {
    const updatedVariations = [...newProduct.variations];
    updatedVariations[index][field] = value;
    setNewProduct((prev) => ({ ...prev, variations: updatedVariations }));
  };

  const handleRemoveVariation = (index) => {
    setNewProduct((prev) => ({
      ...prev,
      variations: prev.variations.filter((_, i) => i !== index),
    }));
  };

  const resetForm = () => {
    setEditProductId(null);
    setNewProduct({
      name: "",
      price: "",
      description: "",
      category: "",
      stock: "",
      type: "simple",
      variations: [],
      store:""
    });
    setImage(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editProductId ? handleUpdateProduct() : handleAddProduct();
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    Object.keys(newProduct).forEach((key) => {
      if (key === "variations" && newProduct.type === "variable") {
        formData.append(key, JSON.stringify(newProduct[key]));
      } else {
        formData.append(key, newProduct[key]);
      }
    });
    if (image) formData.append("image", image);

    try {
      const res = await fetch("http://localhost:5000/api/admin/products", {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (res.ok) {
        const addedProduct = await res.json();
        setProducts((prev) => [...prev, addedProduct]);
        resetForm();
      } else {
        console.error("Failed to add product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const handleEditProduct = (product) => {
    setEditProductId(product._id);
    setNewProduct({
      name: product.name,
      price: product.price || "",
      description: product.description,
      category: product.category,
      stock: product.stock,
      type: product.type,
      variations: product.variations || [],
      store:product.store
    });
    setImage(null);
  };

  const handleUpdateProduct = async () => {
    const formData = new FormData();
    Object.keys(newProduct).forEach((key) => {
      if (key === "variations" && newProduct.type === "variable") {
        formData.append(key, JSON.stringify(newProduct[key]));
      } else {
        formData.append(key, newProduct[key]);
      }
    });
    if (image) formData.append("image", image);

    try {
      const res = await fetch(
        `http://localhost:5000/api/admin/products/${editProductId}`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
        }
      );

      if (res.ok) {
        const updatedProduct = await res.json();
        setProducts((prev) =>
          prev.map((product) =>
            product._id === editProductId ? updatedProduct : product
          )
        );
        resetForm();
      } else {
        console.error("Failed to update product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const res = await fetch(
        `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/products/${id}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.ok) {
        setProducts((prev) => prev.filter((product) => product._id !== id));
      } else {
        console.error("Failed to delete product");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <ManageProductsListings
      products={products}
      categories={categories}
      stores={stores}
      handleSubmit={handleSubmit}
      handleProductInputChange={handleProductInputChange}
      handleVariationChange={handleVariationChange}
      handleAddVariation={handleAddVariation}
      handleRemoveVariation={handleRemoveVariation}
      handleEditProduct={handleEditProduct}
      handleDeleteProduct={handleDeleteProduct}
      handleImageChange={handleImageChange}
      newProduct={newProduct}
      editProductId={editProductId}
    />
  );
}

export default ManageProducts;
