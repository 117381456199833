import React, { useState } from "react";
import axios from "axios";

const ManageLocations = ({ locations, token, stores, setLocations }) => {
  const [newLocation, setNewLocation] = useState({
    name: "",
    address: "",
    city: "", // Add city field
    zipCode: "",
    latitude: "",
    longitude: "",
    operation_hours: "",
    store: "",
    contactNumber: "",
  });

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLocation({ ...newLocation, [name]: value });
  };

  // Handle form submission to add a new location
  const handleAddLocation = (e) => {
    e.preventDefault();

    axios
      .post("http://localhost:5000/api/locations", newLocation, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request header
        },
      })
      .then((response) => {
        setLocations([...locations, response.data.location]);
        setNewLocation({
          name: "",
          address: "",
          city: "", // Reset city field
          zipCode: "",
          latitude: "",
          longitude: "",
          operation_hours: "",
          store: "",
          contactNumber: "",
        });
      })
      .catch((error) => {
        console.error("Error adding location:", error);
      });
  };

  // Handle delete location
  const handleDeleteLocation = (id) => {
    axios
      .delete(`http://localhost:5000/api/locations/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request header
        },
      })
      .then(() => {
        setLocations(locations.filter((location) => location._id !== id));
      })
      .catch((error) => {
        console.error("Error deleting location:", error);
      });
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-6">Manage Locations</h1>

      {/* Form to Add New Location */}
      <form
        onSubmit={handleAddLocation}
        className="bg-white p-6 rounded-lg shadow-md mb-6"
      >
        <h2 className="text-xl font-semibold mb-4">Add New Location</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="text"
            name="name"
            value={newLocation.name}
            onChange={handleInputChange}
            placeholder="Store Name"
            className="p-2 border border-gray-300 rounded"
            required
          />
          <input
            type="text"
            name="address"
            value={newLocation.address}
            onChange={handleInputChange}
            placeholder="Address"
            className="p-2 border border-gray-300 rounded"
            required
          />
          <input
            type="text"
            name="city"
            value={newLocation.city}
            onChange={handleInputChange}
            placeholder="City"
            className="p-2 border border-gray-300 rounded"
            required
          />
          <input
            type="text"
            name="zipCode"
            value={newLocation.zipCode}
            onChange={handleInputChange}
            placeholder="Zip Code"
            className="p-2 border border-gray-300 rounded"
            required
          />
          <input
            type="number"
            name="latitude"
            value={newLocation.latitude}
            onChange={handleInputChange}
            placeholder="Latitude"
            className="p-2 border border-gray-300 rounded"
            required
          />
          <input
            type="number"
            name="longitude"
            value={newLocation.longitude}
            onChange={handleInputChange}
            placeholder="Longitude"
            className="p-2 border border-gray-300 rounded"
            required
          />
          <input
            type="text"
            name="operation_hours"
            value={newLocation.operation_hours}
            onChange={handleInputChange}
            placeholder="Operation Hours (e.g., 9 AM - 6 PM)"
            className="p-2 border border-gray-300 rounded"
            required
          />
          <select
            name="store"
            value={newLocation.store}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded"
            required
          >
            <option value="" disabled>
              Select Store
            </option>
            {stores.map((store) => (
              <option key={store._id} value={store._id}>
                {store.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="contactNumber"
            value={newLocation.contactNumber}
            onChange={handleInputChange}
            placeholder="Contact Number"
            className="p-2 border border-gray-300 rounded"
          />
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
        >
          Add Location
        </button>
      </form>

      {/* List of Locations */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">Existing Locations</h2>
        {locations.length === 0 ? (
          <p>No locations available.</p>
        ) : (
          <ul className="space-y-4">
            {locations.map((location) => (
              <li
                key={location._id}
                className="flex items-center justify-between p-4 border border-gray-300 rounded"
              >
                <div>
                  <h3 className="text-lg font-semibold">{location.name}</h3>
                  <p>{location.address}</p>
                  <p>City: {location.city}</p> {/* Display city */}
                  <p>Hours: {location.operation_hours}</p>
                  <p>Contact: {location.contactNumber || "N/A"}</p>
                </div>
                <button
                  onClick={() => handleDeleteLocation(location._id)}
                  className="bg-red-500 text-white py-1 px-3 rounded"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ManageLocations;