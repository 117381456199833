import React, { useState, useEffect } from "react";

const ManageStore = ({ token, stores, setStores, userId }) => {
  const [form, setForm] = useState({ name: "", location: "", description: "" });
  const [editingStoreId, setEditingStoreId] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const fetchStores = async () => {
    try {
      const res = await fetch("http://localhost:5000/api/admin/stores", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (res.ok) {
        const data = await res.json();
        setStores(data);
      } else {
        console.error("Error fetching stores:", await res.text());
      }
    } catch (error) {
      console.error("Network error fetching stores:", error);
    }
  };

  useEffect(() => {
    fetchStores();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = editingStoreId
      ? `http://localhost:5000/api/admin/stores/${editingStoreId}`
      : "http://localhost:5000/api/admin/stores";
    const method = editingStoreId ? "PUT" : "POST";

    try {
      const res = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...form, owner: userId }),
      });
      if (res.ok) {
        const updatedStore = await res.json();
        setStores((prevStores) => {
          if (editingStoreId) {
            return prevStores.map((store) =>
              store._id === editingStoreId ? updatedStore : store
            );
          } else {
            return [...prevStores, updatedStore];
          }
        });
        setForm({ name: "", location: "", description: "" });
        setEditingStoreId(null);
      } else {
        console.error("Error saving store:", await res.text());
      }
    } catch (error) {
      console.error("Network error saving store:", error);
    }
  };

  const handleEdit = (store) => {
    setForm({ name: store.name, location: store.location, description: store.description });
    setEditingStoreId(store._id);
  };

  const handleDelete = async (id) => {
    try {
      const res = await fetch(`http://localhost:5000/api/admin/stores/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.ok) {
        setStores((prevStores) => prevStores.filter((store) => store._id !== id));
      } else {
        console.error("Error deleting store:", await res.text());
      }
    } catch (error) {
      console.error("Network error deleting store:", error);
    }
  };

  const handleCancel = () => {
    setForm({ name: "", location: "", description: "" });
    setEditingStoreId(null);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Manage Stores</h2>

      {/* Store Form */}
      <form onSubmit={handleSubmit} className="mb-6 bg-gray-700 p-4 rounded-md">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="name">
            Store Name
          </label>
          <input
            type="text"
            name="name"
            value={form.name}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-gray-800 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="location">
            Location
          </label>
          <input
            type="text"
            name="location"
            value={form.location}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-gray-800 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            name="description"
            value={form.description}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-gray-800 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            {editingStoreId ? "Update Store" : "Add Store"}
          </button>
          {editingStoreId && (
            <button
              type="button"
              onClick={handleCancel}
              className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700"
            >
              Cancel
            </button>
          )}
        </div>
      </form>

      {/* Store List */}
      <table className="w-full table-auto bg-gray-700 rounded-md">
        <thead>
          <tr className="bg-gray-800">
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Location</th>
            <th className="px-4 py-2">Description</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {stores.map((store) => (
            <tr key={store._id} className="border-b border-gray-600">
              <td className="px-4 py-2">{store.name}</td>
              <td className="px-4 py-2">{store.location}</td>
              <td className="px-4 py-2">{store.description}</td>
              <td className="px-4 py-2 space-x-2">
                <button
                  onClick={() => handleEdit(store)}
                  className="px-2 py-1 bg-green-600 text-white rounded-md hover:bg-green-700"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(store._id)}
                  className="px-2 py-1 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageStore;
