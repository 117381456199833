import React, { useState } from "react";

const ManagePaymentMethods = ({ paymentMethods, token, setPaymentMethods }) => {
  const [newPaymentMethod, setNewPaymentMethod] = useState({
    name: "",
    description: "",
    isActive: true,
    picture: null, // Added picture field
  });

  const handleAddPaymentMethod = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", newPaymentMethod.name);
    formData.append("description", newPaymentMethod.description);
    formData.append("isActive", newPaymentMethod.isActive);
    if (newPaymentMethod.picture) {
      formData.append("picture", newPaymentMethod.picture);
    }

    try {
      const res = await fetch(
        "http://localhost:5000/api/admin/payment_methods",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (res.ok) {
        const addedMethod = await res.json();
        setPaymentMethods((prev) => [...prev, addedMethod]);
        setNewPaymentMethod({ name: "", description: "", isActive: true, picture: null });
      } else {
        console.error("Failed to add payment method");
      }
    } catch (error) {
      console.error("Error adding payment method:", error);
    }
  };

  const handleDeletePaymentMethod = async (id) => {
    try {
      const res = await fetch(
        `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/payment_methods/${id}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (res.ok) {
        setPaymentMethods(paymentMethods.filter((method) => method._id !== id));
      } else {
        console.error("Failed to delete payment method");
      }
    } catch (error) {
      console.error("Error deleting payment method:", error);
    }
  };

  return (
    <div className="p-6 bg-gray-900 text-white rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold mb-4">Manage Payment Methods</h3>

      <form
        onSubmit={handleAddPaymentMethod}
        className="space-y-4 bg-gray-800 p-4 rounded-lg"
      >
        {/* Payment Method Name */}
        <div>
          <label htmlFor="paymentName" className="block text-sm font-medium mb-2">
            Payment Method Name
          </label>
          <input
            id="paymentName"
            type="text"
            value={newPaymentMethod.name}
            onChange={(e) =>
              setNewPaymentMethod({ ...newPaymentMethod, name: e.target.value })
            }
            placeholder="Enter name"
            className="w-full p-2 border border-gray-700 rounded bg-gray-700 text-white placeholder-gray-400 focus:ring focus:ring-blue-500"
            required
          />
        </div>

        {/* Description */}
        <div>
          <label htmlFor="paymentDescription" className="block text-sm font-medium mb-2">
            Description
          </label>
          <textarea
            id="paymentDescription"
            value={newPaymentMethod.description}
            onChange={(e) =>
              setNewPaymentMethod({ ...newPaymentMethod, description: e.target.value })
            }
            placeholder="Enter description"
            className="w-full p-2 border border-gray-700 rounded bg-gray-700 text-white placeholder-gray-400 focus:ring focus:ring-blue-500"
          ></textarea>
        </div>

        {/* Picture Upload */}
        <div>
          <label htmlFor="paymentPicture" className="block text-sm font-medium mb-2">
            Upload Picture
          </label>
          <input
            id="paymentPicture"
            type="file"
            accept="image/*"
            onChange={(e) =>
              setNewPaymentMethod({ ...newPaymentMethod, picture: e.target.files[0] })
            }
            className="w-full p-2 border border-gray-700 rounded bg-gray-700 text-white placeholder-gray-400 focus:ring focus:ring-blue-500"
          />
        </div>

        {/* Active Toggle */}
        <div className="flex items-center">
          <input
            id="isActive"
            type="checkbox"
            checked={newPaymentMethod.isActive}
            onChange={(e) =>
              setNewPaymentMethod({ ...newPaymentMethod, isActive: e.target.checked })
            }
            className="h-4 w-4 text-blue-600 border-gray-700 rounded focus:ring focus:ring-blue-500"
          />
          <label htmlFor="isActive" className="ml-2 text-sm">
            Active
          </label>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-500 text-white font-medium py-2 px-4 rounded shadow"
        >
          Add Payment Method
        </button>
      </form>

      {/* Payment Methods List */}
      <ul className="mt-6 space-y-4">
        {paymentMethods.map((method) => (
          <li
            key={method._id}
            className="p-4 bg-gray-800 rounded-lg shadow flex justify-between items-center"
          >
            <div>
              <p className="font-bold text-lg">{method.name}</p>
              <p className="text-sm text-gray-400">{method.description}</p>
              {method.picture && (
                <img
                  src={method.picture}
                  alt={method.name}
                  className="mt-2 h-20 w-20 object-cover rounded-lg"
                />
              )}
              <p className="text-sm mt-2">
                <span
                  className={`font-medium ${method.isActive ? "text-green-500" : "text-red-500"}`}
                >
                  {method.isActive ? "Active" : "Inactive"}
                </span>
              </p>
            </div>
            <button
              onClick={() => handleDeletePaymentMethod(method._id)}
              className="text-red-500 hover:text-red-400 font-medium"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManagePaymentMethods;
