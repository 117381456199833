import React, { useState } from "react";

const ManageProductsListings = ({
  products,
  categories,
  stores,
  handleSubmit,
  handleProductInputChange,
  handleVariationChange,
  handleRemoveVariation,
  handleAddVariation,
  handleEditProduct,
  handleDeleteProduct,
  handleImageChange,
  newProduct,
  editProductId
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(5);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  
  // Sorting logic
  const handleSort = (field) => {
    const newSortOrder = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newSortOrder);
  };
  const sortedProducts = [...products].sort((a, b) => {
    if (sortField) {
      const valueA = a[sortField];
      const valueB = b[sortField];
      if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
      if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
    }
    return 0;
  });

  // Pagination logic
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const totalPages = Math.ceil(products.length / productsPerPage);

  return (
    <div className="p-6 bg-gray-100 rounded-lg">
      <h3 className="text-2xl font-semibold mb-4">Manage Products</h3>
      <form
    onSubmit={handleSubmit}
    className="bg-white p-4 shadow-md rounded-md space-y-4"
  >
    <input
      type="text"
      name="name"
      value={newProduct.name}
      onChange={handleProductInputChange}
      placeholder="Product Name"
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
    <select
  name="store"
  value={newProduct.store}
  onChange={handleProductInputChange}
  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
  required
>
  <option value="">Select Store</option>
  {stores.map((store) => (
    <option key={store._id} value={store._id}>
      {store.name}
    </option>
  ))}
</select>

    <select
      name="type"
      value={newProduct.type}
      onChange={handleProductInputChange}
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    >
      <option value="simple">Simple Product</option>
      <option value="variable">Variable Product</option>
    </select>
    {newProduct.type === "simple" && (
      <input
        type="number"
        name="price"
        value={newProduct.price}
        onChange={handleProductInputChange}
        placeholder="Price"
        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        required
      />
    )}
    {newProduct.type === "variable" && (
      <div>
        <h4 className="font-semibold mb-2">Variations</h4>
        {newProduct.variations.map((variation, index) => (
          <div
            key={index}
            className="flex items-center space-x-4 mb-2"
          >
            <input
              type="text"
              value={variation.size}
              onChange={(e) =>
                handleVariationChange(index, "size", e.target.value)
              }
              placeholder="Size"
              className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <input
              type="number"
              value={variation.price}
              onChange={(e) =>
                handleVariationChange(index, "price", e.target.value)
              }
              placeholder="Price"
              className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <button
              type="button"
              onClick={() => handleRemoveVariation(index)}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddVariation}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Add Variation
        </button>
      </div>
    )}
    <textarea
      name="description"
      value={newProduct.description}
      onChange={handleProductInputChange}
      placeholder="Description"
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
    <select
      name="category"
      value={newProduct.category}
      onChange={handleProductInputChange}
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    >
      <option value="">Select Category</option>
      {categories.map((cat) => (
        <option key={cat._id} value={cat._id}>
          {cat.name}
        </option>
      ))}
    </select>
    <input
      type="number"
      name="stock"
      value={newProduct.stock}
      onChange={handleProductInputChange}
      placeholder="Stock"
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
    <input
      type="file"
      onChange={handleImageChange}
      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
    <button
      type="submit"
      className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600"
    >
      {editProductId ? "Update Product" : "Add Product"}
    </button>
  </form>

      {/* Product List with Pagination and Sorting */}
      <div className="mt-8 space-y-4">
        <div className="flex justify-between mb-4">
          <button
            onClick={() => handleSort("store")}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Sort by Store {sortField === "store" && (sortOrder === "asc" ? "↑" : "↓")}
          </button>
          <button
            onClick={() => handleSort("category")}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Sort by Category {sortField === "category" && (sortOrder === "asc" ? "↑" : "↓")}
          </button>
        </div>
        {currentProducts.map((product) => (
          <div
            key={product._id}
            className="flex items-center justify-between bg-white p-4 shadow-md rounded-md"
          >
            <img
              src={product.picture}
              alt={product.name}
              className="w-24 h-24 object-cover rounded-md"
            />
            <div className="flex-1 ml-4">
              <p className="text-lg font-bold">{product.name}</p>
              <p className="text-gray-600">
                {product.price || "Variable Product"}
              </p>
              {product.isVariable && (
                <div>
                  <h4 className="font-semibold">Variations:</h4>
                  {product.variations.map((variation, index) => (
                    <p key={index} className="text-gray-600">
                      {variation.size} - ${variation.price}
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleEditProduct(product)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteProduct(product._id)}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        ))}

        {/* Pagination Controls */}
        <div className="flex justify-center items-center mt-4">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            className={`px-4 py-2 mx-2 ${
              currentPage === 1 ? "bg-gray-400" : "bg-blue-500"
            } text-white rounded-md hover:bg-blue-600`}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
            className={`px-4 py-2 mx-2 ${
              currentPage === totalPages ? "bg-gray-400" : "bg-blue-500"
            } text-white rounded-md hover:bg-blue-600`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageProductsListings;
