import React, { useState, useEffect } from "react";

function ManageFoodCategory({ foodCategories, setFoodCategories, diets, token }) {
  const [selectedDiet, setSelectedDiet] = useState("");
  const [selectedFood, setSelectedFood] = useState("");
  const [foodOptions, setFoodOptions] = useState([]);
  const [foodRating, setFoodRating] = useState("");
  const [editingCategoryId, setEditingCategoryId] = useState(null);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    fetchFoodCategories();
    fetchFoodOptions();
  }, []);

  const fetchFoodCategories = async () => {
    try {
      const res = await fetch("http://localhost:5000/api/admin/food_categories", { headers });
      if (res.ok) {
        const data = await res.json();
        setFoodCategories(data);
      } else {
        console.error("Error fetching food categories:", await res.text());
      }
    } catch (error) {
      console.error("Network error fetching food categories:", error);
    }
  };
  const fetchFoodOptions = async () => {
    try {
      const res = await fetch("https://supplement-app-d151447d38d4.herokuapp.com/api/admin/foods", { headers });
      if (res.ok) {
        const data = await res.json();
        setFoodOptions(data);
        console.log(data);
      } else {
        console.error("Error fetching food options:", await res.text());
      }
    } catch (error) {
      console.error("Network error fetching food options:", error);
    }
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    const categoryData = {
      diet: selectedDiet,
      food: selectedFood,
      rating: Number(foodRating),
    };

    try {
      const url = editingCategoryId
        ? `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/food_categories/${editingCategoryId}`
        : "https://supplement-app-d151447d38d4.herokuapp.com/api/admin/food_categories";

      const method = editingCategoryId ? "PUT" : "POST";

      const res = await fetch(url, {
        method,
        headers,
        body: JSON.stringify(categoryData),
      });

      if (res.ok) {
        const updatedCategory = await res.json();
        if (editingCategoryId) {
          setFoodCategories((prevCategories) =>
            prevCategories.map((cat) =>
              cat._id === updatedCategory._id ? updatedCategory : cat
            )
          );
        } else {
          setFoodCategories((prevCategories) => [...prevCategories, updatedCategory]);
        }
        setSelectedDiet("");
        setSelectedFood("");
        setFoodRating("");
        setEditingCategoryId(null);
      } else {
        console.error("Error saving food category:", await res.text());
      }
    } catch (error) {
      console.error("Network error saving food category:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await fetch(
        `https://supplement-app-d151447d38d4.herokuapp.com/api/admin/food_categories/${id}`,
        {
          method: "DELETE",
          headers,
        }
      );

      if (res.ok) {
        setFoodCategories((prevCategories) =>
          prevCategories.filter((cat) => cat._id !== id)
        );
      } else {
        console.error("Error deleting food category:", await res.text());
      }
    } catch (error) {
      console.error("Network error deleting food category:", error);
    }
  };

  const handleEdit = (category) => {
    setSelectedDiet(category.diet._id);
    setSelectedFood(category.food._id);
    setFoodRating(category.rating);
    setEditingCategoryId(category._id);
  };

  const getDietName = (dietId) => {
    const diet = diets.find((d) => d._id === dietId);
    return diet ? diet.name : "Unknown Diet";
  };

  const getFoodName = (foodId) => {
    const food = foodOptions.find((f) => f._id === foodId);
    return food ? food.name : "Unknown Food";
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Manage Food Categories</h2>

      {/* Add/Edit Food Category Form */}
      <form
        className="mb-4 bg-gray-800 p-4 rounded-md"
        onSubmit={handleSubmit}
      >
        <div className="mb-2">
          <label className="block text-gray-300">Select Diet</label>
          <select
            value={selectedDiet}
            onChange={(e) => setSelectedDiet(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300"
            required
          >
            <option value="">Choose Diet</option>
            {diets.map((diet) => (
              <option key={diet._id} value={diet._id}>
                {diet.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2">
          <label className="block text-gray-300">Select Food</label>
          <select
            value={selectedFood}
            onChange={(e) => setSelectedFood(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300"
            required
          >
            <option value="">Choose Food</option>
            {foodOptions.map((food) => (
              <option key={food._id} value={food._id}>
                {food.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2">
          <label className="block text-gray-300">Rating</label>
          <input
            type="number"
            value={foodRating}
            onChange={(e) => setFoodRating(e.target.value)}
            className="w-full p-2 rounded bg-gray-700 text-gray-300"
            placeholder="Enter rating"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          {editingCategoryId ? "Update Category" : "Add Category"}
        </button>
      </form>

      {/* Food Categories List */}
      <table className="w-full bg-gray-800 text-gray-300 rounded-md">
        <thead>
          <tr>
            <th className="p-2 text-left">Diet</th>
            <th className="p-2 text-left">Food</th>
            <th className="p-2 text-left">Rating</th>
            <th className="p-2 text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {foodCategories.map((category) => (
            <tr key={category._id} className="border-t border-gray-700">
              <td className="p-2">{getDietName(category.diet)}</td>
              <td className="p-2">{getFoodName(category.food)}</td>
              <td className="p-2">{category.rating}</td>
              <td className="p-2">
                <button
                  onClick={() => handleEdit(category)}
                  className="bg-yellow-600 text-white px-3 py-1 rounded mr-2 hover:bg-yellow-700"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(category._id)}
                  className="bg-red-600 text-white px-3 py-1 rounded hover:bg-red-700"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ManageFoodCategory;
